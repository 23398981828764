import './App.css';
import * as React from 'react';
// import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';

import axios from 'axios';

function App() {
  const [items, setItems] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const valueRef = React.useRef('');

  const getGifts = (query) => {
    setMessage("");
    setLoading(true);
    axios.get(`https://gift-guesser-dot-mockcode.uc.r.appspot.com/?query=${query}`)
      .then(res => {
        if (res.data?.data?.length === 0) {
          setMessage("Oh no! We couldn't find any gifts for that query. Try something else?")
        } else {
        setItems(res.data?.data);
        setLoading(false);
        }
    });
  }

  const theme = createTheme({
    typography: {
      fontFamily: [
        'IBM Plex Mono',
        'monospace',
      ].join(','),
    },
  });
    
  return (
    <div className="App">
      <header className="App-header">
      <ThemeProvider theme={theme}>
        <Grid container sx={{alignItems: "center", justifyContent: "center", paddingTop: {xs: "10vh", sm: "20vh"}}}>
        <Grid item xs={11} sm={9} lg={6}>
        <Typography variant="h3">Welcome to <b style={{color: "#1976d2"}}>GiftMatch</b></Typography>
        <Typography variant="h5">The ultimate gift suggester</Typography>
        </Grid>
        <Grid item xs={11} sm={9} lg={6}sx={{margin: 3, border: "4px solid lightgray", borderStyle: "solid none none none"}}>
        
        </Grid>
          <Grid item xs={11} sm={9} lg={6}>
            <TextField 
              sx={{width: "100%"}}
              multiline
              id="outlined-basic" 
              label="Suggest gifts for someone who..." 
              variant="outlined"
              inputRef={valueRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={() => {getGifts(valueRef.current?.value);}}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {isLoading && <LinearProgress />}
            {message && <Typography variant="h6" sx={{mt: 1, textAlign: "center", color: "red"}}>{message}</Typography>}
            {items && items.map((item, index) => (
              <Card 
                onClick={() => {window.open(item?.offer?.offer_page_url)}} 
                sx={{borderRadius: "10px", my: 1, padding: 1,
                ':hover': {
                  boxShadow: 5, // theme.shadows[20]
                  cursor: "pointer",
                  borderColor: "#1976d2"
                }}} 
                
                variant="outlined"
              >

                <Typography variant="h6" sx={{float: "left", textAlign: "left"}}>
                  {item?.product_photos && item?.product_photos.length > 0 && <Avatar variant="rounded" sx={{height: "25px", mx: 1, display: "inline-block"}} alt={item?.product_title} src={item?.product_photos[0]} />}

                  {item?.product_title}
                </Typography>
                <div>
                <Chip label={item?.offer?.price} sx={{ml: 0.5, float: "right"}}/>
                {item?.product_rating && <Chip variant="outlined" label={"Rating: " + item?.product_rating + "/5"} sx={{ml: 0.5, float: "right"}}/>}
                </div>
                <br></br>
                <Typography variant="subtitle2" sx={{textAlign: "left", color: "gray", float: "left"}}>
                  {item?.product_description && item?.product_description.split(".")[0]}
                </Typography>
                </Card>
            ))}
        </Grid>
        </Grid>
        </ThemeProvider>
      </header>
    </div>
  );
}

export default App;
